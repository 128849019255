import styled from "styled-components";
import { CustomDropDown } from "../../PeopleHomeV2/styles";

const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
text-align: center;
color: ${({labelStyle}) => labelStyle && labelStyle.color ? labelStyle.color : '#9C9C9C'} ;
display: flex;
margin: auto;
margin: ${({labelStyle}) => labelStyle && labelStyle.margin ? labelStyle.margin : 'none'} ;
`;

const YearDropDown = styled(CustomDropDown)`
display:flex;
  float: right;
  align-items: center;
  ${'' /* margin-left: 60px; */}
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  border:1px solid #afcbd3;
  background-color: #faf9f4;
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  /* margin-right: 15px; */
  padding: 10px 13px 10px 13px;
  .open>.dropdown-men{
    margin-top: -40px;
  }
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
  .caret{
    color: #005c87;
    float: left;
    margin-left :13px;
  }
  button{
    background:transparent !important;
    border:none;
    color:#005c87;
  }

  button:hover{
    color:#005c87;
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
  }
`;


export {YearDropDown, NewLabel}