/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  StyledModal, CalendarHeader, CalendarWrapper, ConfirmVisitText, ButtonContainer, ButtonV2
} from '../PeopleHomeV2/ManageBuddies/styles';

class DeletePopup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { deleteId, showConfirmPopup, showHideDeletePopup, deleteData, popupTitle, popupHeading } = this.props;
    return (
      <StyledModal show={showConfirmPopup} width={"485px"}>
        <CalendarHeader>
          <div>{popupTitle}</div>
        </CalendarHeader>
        <CalendarWrapper padding="0 25px 25px 25px">
          {
            <ConfirmVisitText>
              {popupHeading}
            </ConfirmVisitText>
          }
          <ButtonContainer>
            <ButtonV2
              background="rgb(253,113,117)"  
              onClick={() => deleteData(deleteId)}
            >
              {"Delete"}
            </ButtonV2>
            <ButtonV2
              border="1"
              background="transparent"
              color="#9c9c9c"
              onClick={() => showHideDeletePopup()}
            >
              {"Cancel"}
            </ButtonV2>
          </ButtonContainer>
        </CalendarWrapper>
      </StyledModal>
    );
  }
}

DeletePopup.propTypes = {
  deleteId:PropTypes.number,
  showConfirmPopup: PropTypes.bool,
  showHideDeletePopup: PropTypes.func,
  deleteData: PropTypes.func,
  popupHeading: PropTypes.string,
  popupTitle: PropTypes.string
};

export default DeletePopup;