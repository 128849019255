import PropTypes from "prop-types";
import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  width:${({width}) => width ? width : "30px"};
  height:${({height}) => height ? height : "30px"};
  animation: ${spin} 1s infinite linear;
  border: solid 3px ${({ color }) => color ? color : "#005C87"};
  border-radius: 50%;
  border-bottom-color: transparent;
  border-right-color: transparent;
  will-change: transform;
`;


const Spinner = (props) => {
  const{width,height, style, color}= props;
  useEffect(()=>{},[])
  return (
    <SpinnerContainer style={{style}} className="spinner" color={color || "#005C87"} width={width || "30px"} height={height || "30px"}>
    </SpinnerContainer>
  );
};

Spinner.propTypes = {
  width:PropTypes.string,
  height: PropTypes.string,
  style:PropTypes.object,
  color:PropTypes.string,
}

export default Spinner;
