import React from "react";
import PropTypes from "prop-types";
import LazyImage from "./LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";

const NoDataCard = (props) => {
  const{title, btnText, btnOnclick,btnColor, imagePath, style, showButton}=props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "80px",
        ...style
      }}
    >
      <LazyImage width={400} src={ImageUrl + imagePath} />
      <span style={{ marginTop: "25px", width:'507px', textAlign:'center',color: "#005C87",fontSize: "16px",fontWeight: "400",lineHeight: "20px"}}>
        {title}
      </span>
      {showButton && <button
        onClick={btnOnclick}
        style={{
          marginTop: "25px",
          background: btnColor,
          height: "50px",
          width: "320px",
          borderRadius: "3px",
          border: "none",
          color: "white",
          fontSize: "18px",
          fontFamily: "Rubik-Medium",
        }}
      >
        {btnText}
      </button>}
    </div>
  );
};

NoDataCard.propTypes = {
  title: PropTypes.string,
  btnText:PropTypes.string,
  btnOnclick:PropTypes.func,
  btnColor:PropTypes.string,
  imagePath:PropTypes.string,
  style:PropTypes.object,
  showButton:PropTypes.bool,
};

export default NoDataCard;
