import React from 'react';
import styled, { keyframes } from 'styled-components';
import PropTypes from 'prop-types';


const loadingAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const SkeletonWrapper = styled.div`
  width:  ${({width})=> width ? width : 'none'}; 
  height: ${({height})=> height ? height : 'none'};
  background-color: #005c87;
  opacity: 8%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: ${loadingAnimation} 1.5s infinite;
  }
`;

const SkeletonLoder = ({ width, height, backgroundColor,style, ...rest }) => (
  <SkeletonWrapper width={width} height={height} backgroundColor={backgroundColor} {...rest} style={{...style}} />
);

SkeletonLoder.propTypes = {
  width: PropTypes.any,
  height: PropTypes.any,
  backgroundColor: PropTypes.any,
  style: PropTypes.object
}

export default SkeletonLoder;

