import styled from "styled-components";
import { CustomDropDown } from "../../PeopleHomeV2/styles";

const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
text-align: center;
color: ${({labelStyle}) => labelStyle && labelStyle.color ? labelStyle.color : 'rgba(0, 92, 135, 0.6)'} ;
display: flex;
margin: auto;
margin: ${({labelStyle}) => labelStyle && labelStyle.margin ? labelStyle.margin : '0px'} ;
margin-right: ${ ({labelStyle}) => labelStyle && labelStyle.marginRight ? labelStyle.marginRight : "10px" };
`;

const DropdownArrow = styled.img`
  float: right;
  ${'' /* top:${({ drop, top }) =>top?top: drop ? "67px" : " 60px"}; */}
  position: relative;
  ${'' /* right: 325px; */}
  left: 80px;
  display:block;
  z-index:5;
  width: 100%;
`;

const YearDropDown = styled(CustomDropDown)`
display:flex;
  float: right;
  align-items: center;
  ${'' /* margin-left: 60px; */}
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 93px;
  ${'' /* height: 40px; */}
  border:${({style}) => style && style.border ? style.border : 'none'};
  ${'' /* background-color: ${({style}) => style && style.background ? style.background : 'rgba(105, 194, 255, 0.1)'}; */}
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  margin-right: 15px;
  padding: 10px 13px 10px 13px;
  color: #005c87;
  font-size: 16px;
  .open>.dropdown-men{
    margin-top: -40px;
  }
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
  .caret{
    display: none;
  }

  button{
    background:${({ background }) => background ? background + "!important" : '#F7F5EB80 !important'};
    border:none !important;
    color:#005C87;
    width: 100%;
    padding: 8px;
  }

  button:hover{
    color:#005C87;
    background-color: transparent !important;
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  button:active{
    background-color: transparent !important;
  }

  .btn.show {
    color: #333 !important;
    ${'' /* background-color: #d4d4d4 !important; */}
    background-color:${({ background }) => background ? background : '#d4d4d4 !important'};
    border-color: #8c8c8c !important;
  }

  .dropdown-toggle::after{
    display: none
  }
  .dropdown-menu.show {
    display:${({options}) =>!options &&  "none !important"};
    min-width: 89px;
    font-size: 14px;
  }
`;

/* const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #9C9C9C;
margin-right: 10px;
position: relative;
right: 275px;
bottom: ${({role}) => (role === 'ADMIN') ? '3px' : '0px'};
`; */

/* const YearDropDown = styled(CustomDropDown)` */
/* float: right;
  align-items: center;
  margin-left: 60px;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  background-color: rgba(105, 194, 255, 0.1);
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : '0px'};
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  } */
/* `; */

export {YearDropDown, NewLabel, DropdownArrow}