import React from "react";
import PropTypes from "prop-types";
import { NewLabel, YearDropDown } from "./style";
import { DropdownItem } from "react-bootstrap";

export default function YearDropdown({title,labelStyle, role, data, onSelect, style, refOutside, toggleMenu,background}) {
  return (
    <React.Fragment>
      <NewLabel role={"role"} labelStyle={labelStyle} style={{display:"flex",alignItems:"center",color: "rgba(0, 92, 135, 0.6)"}}>Year</NewLabel>
      <YearDropDown
        ref={refOutside ? refOutside : null}
        inputpadding="8px"
        type="button"
        label="year"
        title={title}
        id="year"
        role={role}
        style = {style}
        onSelect={(list, /*listkey*/) => onSelect(list)}
        onClick={toggleMenu}
        background={background}
        options={data?.length > 1 ? true : false}
      >
        {data?.map((list, index) => (
          <DropdownItem key={index}  eventKey={list} style={{ color: 'rgba(0, 122, 177, 1)' }}>
            {list}
          </DropdownItem>
        ))}
      </YearDropDown>
    </React.Fragment>
  );
}

YearDropdown.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  role:PropTypes.any,
  labelStyle:PropTypes.obj,
  style:PropTypes.obj,
  refOutside: PropTypes.any,
  toggleMenu: PropTypes.any,
  background:PropTypes.string,
  bgColor: PropTypes.any
};