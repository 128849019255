import React from "react";
import PropTypes from "prop-types";
import { NewLabel, YearDropDown } from "./style";
import { DropdownItem } from "react-bootstrap";

export default function YearDropdownPopup({title,labelStyle, style,role, data, onSelect,popUpName}) {
  return (
    <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
      <NewLabel role={"role"} labelStyle={labelStyle}>Year</NewLabel>
      <YearDropDown
        inputpadding="9px"
        type="button"
        label="year"
        title={title}
        id="year"
        role={role}
        style={style}
        onSelect={(list, /*listItem*/)=>{onSelect(list,popUpName);}}
      >
        {data.map((list, index) => (
          <DropdownItem key={index} eventKey={list} onSelect={() => onSelect(list,popUpName)}>
            {list}
          </DropdownItem>
        ))}
      </YearDropDown>
    </div>
  );
}

YearDropdownPopup.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  role:PropTypes.any,
  labelStyle:PropTypes.obj,
  style:PropTypes.obj,
  popUpName : PropTypes.string
};